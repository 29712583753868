export default {
	data: () => ({
		teams: {
			id: 0,
			name: "",
			description: ""
		},
    loading: false,
		error: "",
		backUrl: "/teams"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.teams = {
				id: 0,
				name: "",
				description: "",
			};
		},
		validateTeams() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
			this.teams.id > 0 ? this.update() : this.add();
		},
		update() {
			let _vm = this;
			this.axios
				.post("/teams/" + this.teams.id, {
					...this.teams,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.teams = response.data.data;
					_vm.$router.push(_vm.backUrl);
				});
		},
		add() {
			let _vm = this;
			this.axios
				.post("/teams", _vm.teams)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				});
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/teams/" + this.$route.params.id)
				.then(function (response) {
					_vm.teams = response.data.data;
				})
				.catch(function () { });
		},

	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
